import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import ProductList from "../../components/home/ProductList/ProductList";
import Body from "../../components/home/Body/Body";
import Women from "../../components/home/Women/Women";
import Men from "../../components/home/Men/Men";
const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto">
        <Body />
        <Men />
        <Women />
        <ProductList />
      </div>
    </div>
  );
};

export default Home;
