import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Product from "../Products/Product"; 
import SampleNextArrow from "../Men/SampleNextArrow";
import SamplePrevArrow from "../Men/SamplePrevArrow";
import Slider from "react-slick";
const BASE_URL = process.env.REACT_APP_API_URL;

const TABS_API_MAPPING = {
  populaire: `${BASE_URL}/api/products/popular/`, 
  promo: `${BASE_URL}/api/products/badge/Promo/`,
};

const ProductList = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("populaire");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const apiEndpoint = TABS_API_MAPPING[selectedTab];
      try {
        const response = await axios.get(apiEndpoint);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [selectedTab]);

  const settings = {
    infinite: products.length >= 4, // Infinite loop only if there are 4 or more products
    speed: 500,
    slidesToShow: 4, // Always show 4 slides
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: products.length >= 3, // Adjust infinite behavior for 3 products
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: products.length >= 2, // Adjust infinite behavior for 2 products
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true, // Allow infinite for small screens
        },
      },
    ],
  };

  return (
    <div className="w-full">
      {/* Navigation Tabs */}
      <nav className="flex justify-start space-x-12 border-b border-gray-200 mb-4 sm:pt-4 ml-8">
        {["populaire", "promo"].map((tab) => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`text-lg pb-2 ${
              selectedTab === tab
                ? "text-gray-800 border-b-2 border-gray-800 font-semibold"
                : "text-gray-500"
            } hover:text-gray-800 transition duration-200`}
          >
            {t(tab)}
          </button>
        ))}
      </nav>

      {/* Products Grid */}
      {products.length === 0 ? (
        <div className="text-center text-gray-500">
          
        </div>
      ) : (
        <Slider {...settings}>
          {products.map((product) => (
            <div
              className="px-2"
              key={product.product_Id}
              style={{
                width: "350px", // Fixed width
                height: "400px", // Fixed height to keep uniformity
              }}
            >
              <Product
                _id={product.product_Id}
                img={`${BASE_URL}${product.product_images[0]?.image}`}
                images={product.product_images}
                productName={product.product_name}
                price={product.price}
                promo_percentage={product.promo_percentage}
                discounted_price={product.discounted_price}
                colors={product.colors}
                badge={product.badge}
                des={product.description}
                gender={product.gender}
                season={product.season}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ProductList;

