import React from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { motion } from "framer-motion";
import "./About.css"; 
import { ariabr3 } from "../../assets/images/index";
import { useTranslation } from "react-i18next";

const About = () => {
  // Variants for animations
  const imageVariants = {
    hidden: { opacity: 0, scale: 0.5 }, // Slightly scale down for effect
    visible: { opacity: 1, scale: 1 },
  };

  // Staggered text variants for animation
  const textVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };
  const { t } = useTranslation();
  return (
    <div className="about-section" id="id">
      <motion.div
        className="image-container"
        initial="hidden"
        animate="visible"
        transition={{ duration: 2 }} // Duration for the image animation
        variants={imageVariants}
      >
        <img src={ariabr3} alt="Summer Collection" />
      </motion.div>

      <motion.div
        className="text-container"
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 2.5 }} // Delay the text animation to start after the image animation
        variants={textVariants}
      >
      
      {/* <div className="w-full py-10 xl:py-10 flex flex-col gap-3 ">
      <h1 className="text-5xl text-primeColor font-titleFont font-bold">
      {t("How")}
      </h1> */}

<div className="w-full py-10 xl:py-14 flex flex-col gap-2">
  <div className="font-poppins font-bold text-4xl text-black">
    {t("How")}
  </div>

      
    </div>

        {/* Staggered Animation for Paragraphs */}
        <motion.p
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.8, delay: 2 }} // First paragraph - slower
          variants={textVariants}
        >
          {t("Unique")}
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.9, delay: 2.8 }} // Second paragraph - slower
          variants={textVariants}
        >
          {t("Aboutsection")}
        </motion.p>

      
      </motion.div>
    </div>
  );
};

export default About;
