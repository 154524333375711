import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

const BASE_URL = process.env.REACT_APP_API_URL;
const HeaderBottom = () => {
  const products = useSelector((state) => state.shopReducer.products);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [Searchproducts, setSearchProducts] = useState([]); // State to hold product data

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Close the dropdown and clear search input if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        // setShow(false);
        setSearchQuery(""); // Clear the search input
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/products/`);
        console.log("Fetched Products:", response.data);
        setSearchProducts(response.data); // Set the fetched products into state
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const filtered = Searchproducts.filter((item) =>
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, Searchproducts]);

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">

          {/* Search bar */}
          <div className="mx-auto w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl relative">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder={t("searchp")}
            />
             <FaSearch className="w-5 h-5" />
            {searchQuery && (
              <div
              ref={dropdownRef}
              className="w-full mx-auto h-96 bg-white top-16 absolute left-0 right-0 z-50 overflow-y-scroll shadow-2xl scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 cursor-pointer rounded-lg border border-gray-200"
            >
              {filteredProducts.length > 0 ? (
                filteredProducts.map((item) => (
                  <div
                    onClick={() => {
                      const productItem = {
                        _id: item.product_Id,
                        img: `${BASE_URL}${item.product_images[0]?.image}`,
                        images: item.product_images,
                        productName: item.product_name,
                        price: item.price,
                        promo_percentage: item.promo_percentage,
                        discounted_price: item.discounted_price,
                        colors: item.colors,
                        badge: item.badge,
                        des: item.description,
                        gender: item.gender,
                        season: item.season,
                      };
                      navigate(
                        `/product/${item.product_name.toLowerCase().split(" ").join("")}`,
                        {
                          state: {
                            item: productItem,
                          },
                        }
                      );
                      setSearchQuery("");
                    }}
                    key={item._id}
                    className="mx-auto h-32 bg-gray-100 mb-3 flex items-center gap-3 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors duration-150"
                  >
                    <img
                      className="w-24 h-32 rounded-md"
                      src={`${BASE_URL}${item.product_images[0]?.image}`}
                      alt="productImg"
                    />
                    <div className="flex flex-col gap-1">
                      <p className="font-poppins font-medium text-lg">{item.product_name}</p>
                      <p className="text-xs text-gray-600">{item.description}</p>
                      <p className="text-sm">
                        {t("Price")}:{" "}
                        <span className="text-primeColor font-semibold">
                          {item.price} TND
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center h-full text-gray-500 text-lg font-medium border border-gray-300 bg-gray-100 rounded-lg  shadow-md">
                  {t("productf")}
                </div>
              )}
            </div>
            
            )}
          </div>

          {/* Cart */}
          <div className="hidden md:flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <Link to="/cart">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
