import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/shopSlice";
import { useTranslation } from "react-i18next";
const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [quantityAvailable, setQuantityAvailable] = useState(0);
  const [notAvailable, setNotAvailable] = useState(false);
  const { t } = useTranslation();
  if (!productInfo || !productInfo.colors) {
    return <p>Loading product details...</p>;
  }


  const handleColorChange = (e) => {
    const chosenColor = productInfo.colors.find(
      (color) => color.color_name === e.target.value
    );
    setSelectedColor(chosenColor);
    setSelectedSize(null); 
    setQuantity(1); 
    setQuantityAvailable(0);
    setNotAvailable(false);
  };


  const handleSizeChange = (e) => {
    if (!selectedColor) return;
    const chosenSize = selectedColor.sizes.find(
      (size) => size.size === e.target.value
    );
    setSelectedSize(chosenSize);
    setQuantityAvailable(chosenSize.quantity);
    setNotAvailable(chosenSize.quantity === 0);
    setQuantity(1); // Reset quantity if size changes
  };

  // Handle quantity change
  const handleQuantityChange = (e) => {
    const newQuantity = Math.min(e.target.value, quantityAvailable); // Ensure quantity does not exceed available stock
    setQuantity(newQuantity);
  };

  // Handle Add to Cart
  const handleAddToCart = () => {
    if (selectedColor && selectedSize && quantityAvailable >= quantity) {
      dispatch(
        addToCart({
          _id: productInfo._id,
          productName: productInfo.productName,
          des: productInfo.des,
          quantity: quantity,
          image: productInfo.img,
          images: productInfo.images,
          badge: productInfo.badge,
          price: productInfo.price,
          colors: productInfo.colors ,
          color: selectedColor.color_name,
          size: selectedSize.size,
          gender: productInfo.gender,
          season: productInfo.season,
          discounted_price:productInfo.discounted_price
        })
      );
    } else {
      setNotAvailable(true);
    }
  };

  

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-poppins font-medium">{productInfo.productName}</h2>
      {productInfo.promo_percentage!=="0.00"  ? (
    <><p>
      <span className="text-primeColor font-bold line-through">{productInfo.price} TND</span>
      <span className="ml-6 text-primeColor font-bold">
        {productInfo.discounted_price} TND
      </span>
      </p>
      
    </>
  ) : (
    <p className="text-xl font-semibold">{productInfo.price} TND</p>
  )}
      <p className="text-base text-gray-600">{productInfo.des}</p>
      {productInfo.gender === "Women" && (
        <p
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#ff69b4",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <strong>{t("unique")}</strong>
        </p>
      )}
      {productInfo.gender === "Men" && (
         <p
         style={{
           fontSize: "24px",
           fontWeight: "bold",
           color: "#708090", // silver color
           textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // soft white shadow
           fontFamily: "Arial, sans-serif",
         }}
       >
          <strong>{t("stand")}</strong>
        </p>
      )}
      <div className="mt-4">
        <label className="font-semibold">{t("SelectC")}: </label>
        <select onChange={handleColorChange} value={selectedColor?.color_name || ""}>
          <option value="" disabled>{t("SelectC")}</option>
          {productInfo.colors.map((color) => (
            <option key={color.color_name} value={color.color_name}>
              {color.color_name}
            </option>
          ))}
        </select>
      </div>

      {selectedColor && (
        <div className="mt-4">
          <label className="font-semibold">{t("Selects")}: </label>
          <select onChange={handleSizeChange} value={selectedSize?.size || ""}>
            <option value="" disabled>{t("Selects")}</option>
            {selectedColor.sizes.map((size) => (
              <option key={size.size} value={size.size}>
                {size.size}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedSize && (
        <div className="mt-4">
          <label className="font-semibold">{t("Selectq")}: </label>
          <input
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            min="1"
            max={quantityAvailable}
            className="w-20 p-2 border rounded"
          />
        </div>
      )}

      {notAvailable && (
        <p className="text-red-500 mt-2">{t("productN")}</p>
      )}

      <button
        onClick={handleAddToCart}
        disabled={!selectedColor || !selectedSize || quantityAvailable === 0}
        className={`w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont ${
          !selectedColor || !selectedSize || quantityAvailable === 0
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
      >
        {t("Tocart")}
      </button>
    </div>
  );
};

export default ProductInfo;
