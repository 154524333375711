import React, { useState } from "react";
import Category from "./shopBy/Category";

const ShopSideNav = ({ updateProducts, closeSidebar }) => {
  const [openCategory, setOpenCategory] = useState(null); // Track the open category

  // Function to toggle category visibility
  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category); // Toggle open/close state
  };

  return (
    <div className="mt-8 w-full flex flex-col gap-6">
      {/* Passing down the open state and toggle function to each Category */}
      <Category
        gender="Men"
        updateProducts={updateProducts}
        closeSidebar={closeSidebar}
        isOpen={openCategory === "Men"}
        toggleCategory={() => toggleCategory("Men")}
      />
      <Category
        gender="Women"
        updateProducts={updateProducts}
        closeSidebar={closeSidebar}
        isOpen={openCategory === "Women"}
        toggleCategory={() => toggleCategory("Women")}
      />
    </div>
  );
};

export default ShopSideNav;
