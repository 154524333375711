import React from "react";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { loading } from "../../../assets/images/index";
import { useTranslation } from "react-i18next";
const Product = (props) => {
  const { t } = useTranslation();
  const _id = props.productName;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);

  const navigate = useNavigate();
  const productItem = props;

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };

  return (
    <div className="w-full relative group">
      <div
        className="relative overflow-hidden"
        style={{
          width: "100%",
          height: "auto",
        }}
      >
        <style>
          {`
            @media (min-width: 768px) {
              .product-container {
                width: 350px;
                height: 450px;
              }
            }
            @media (min-width: 1024px) {
              .product-container {
                width: 350px;
                height: 450px;
              }
            }
          `}
        </style>
        <div className="product-container w-full h-full">
          <Image className="w-full h-full object-cover" imgSrc={props.img} placeholderSrc={loading} />
        </div>
        <div className="w-full h-32 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={handleProductDetails}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              {t("View")}
              <span className="text-lg">
                <MdOutlineLabelImportant />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4" style={{ width: "100%" }}>
        {/* Product Name */}
        <h2 className="text-lg text-primeColor font-poppins font-medium">{props.productName}</h2>
        {/* Price and Discount */}
        <div className="text-[#767676] text-[14px] flex flex-col sm:flex-row justify-between">
          {props.promo_percentage !== "0.00" ? (
            <>
              <span className="line-through">{props.price} TND</span>
              <span className="ml-2 text-primeColor font-bold">
                {props.discounted_price} TND
              </span>
            </>
          ) : (
            <span>{props.price} TND</span>
          )}
        </div>
        {/* Available Colors */}
        <div>
          <p className="text-[#767676] text-[14px]">{t("available")}:</p>
          <ul className="flex gap-2">
            {props.colors.map((color, index) => (
              <li key={index} className="text-[#767676] text-[14px]">
                {color.color_name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Product;
