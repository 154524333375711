import React, { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;
const ImageManagement = () => {
  const [images, setImages] = useState([]);
  const [editingImage, setEditingImage] = useState(null);
  const [formData, setFormData] = useState({
    image: null,
    image_type: 'banner',
  });
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Fetch images when component mounts
  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = () => {
    fetch(`${BASE_URL}/api/images/`)
      .then(response => response.json())
      .then(data => setImages(data))
      .catch(error => console.error('Error fetching images:', error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent double submissions
    setIsSubmitting(true);
    const method = editingImage ? 'PUT' : 'POST';
    const url = editingImage ? `${BASE_URL}/api/images/${editingImage.id}/` : `${BASE_URL}/api/images/`;
   
    const form = new FormData();
    form.append('image', formData.image);
    form.append('image_type', formData.image_type);

    try {
      const response = await fetch(url, {
        method: method,
        body: form,
      });

      const data = await response.json();

      if (editingImage) {
        setImages(images.map(img => (img.id === editingImage.id ? data : img)));
      } else {
        setImages([...images, data]);
      }

      resetForm();
    } catch (error) {
      console.error('Error submitting image:', error);
    } finally {
      setIsSubmitting(false); // End the submission process
    }
  };

  const handleDelete = (id) => {
    fetch(`${BASE_URL}/api/images/${id}/`, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          setImages(images.filter(image => image.id !== id));
        }
      })
      .catch(error => console.error('Error deleting image:', error));
  };

  const handleEdit = (image) => {
    setEditingImage(image);
    setFormData({
      image: null, // Reset file input
      image_type: image.image_type,
    });
    setShowForm(true); // Show form when editing
  };

  const resetForm = () => {
    setEditingImage(null);
    setShowForm(false); // Hide form after reset
    setFormData({
      image: null,
      image_type: 'banner',
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Manage Images</h1>

      {/* Button to add a new image */}
      <button 
        onClick={() => {
          resetForm(); // Reset the form before adding
          setShowForm(true); // Show the form
        }}
        className="bg-blue-500 text-white p-2 rounded mb-4"
      >
        Add Image
      </button>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">{editingImage ? 'Edit Image' : 'Add Image'}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Image:</label>
                <input 
                  type="file" 
                  name="image" 
                  onChange={handleFileChange} 
                  required 
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Type:</label>
                <select
                  name="image_type"
                  value={formData.image_type}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="banner">Banner</option>
                  <option value="rounded">Rounded</option>
                </select>
              </div>
              <div className="flex justify-end mt-4">
                <button 
                  type="submit" 
                   className={`bg-green-500 text-white px-4 py-2 rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Please wait...' : (editingImage ? 'Update Image' : 'Add Image')}
                </button>
                <button 
                  type="button" 
                  onClick={resetForm} 
                  className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Display Images */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mt-6">
        {images.map(image => (
          <div key={image.id} className="border p-4 rounded shadow">
            <h3 className="text-lg font-bold mb-2">({image.image_type})</h3>
            <img 
              src={`${BASE_URL}${image.image}`} 
              alt={image.image_type} 
              className="w-40 h-40 object-cover mb-2"
            />
            <div>
              <button 
                onClick={() => handleEdit(image)} 
                className="text-blue-500 underline"
              >
                Edit
              </button>
              <button 
                onClick={() => handleDelete(image.id)} 
                className="text-red-500 underline ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageManagement;
