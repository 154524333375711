import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Badge from "../home/Products/Badge";
import Image from "../designLayouts/Image";

const BASE_URL = process.env.REACT_APP_API_URL;

const Products = () => {
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({
        product_name: '',
        description: '',
        price: '',
        promo_percentage:'0.00',
        badge: 'No Badge',
        gender: 'Men',
        season: 'New Arrivals',
        colors: [],
        product_images: [],
        existing_images: [], 
        is_popular: false 
    });
  

    const productsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    
    // Calculate total pages
    const totalPages = Math.ceil(products.length / productsPerPage);
  
    // Calculate the current products to display
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  
    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
  
    // Function to generate pagination numbers
    const generatePaginationNumbers = () => {
      const paginationNumbers = [];
      const maxPagesToShow = 2; // Number of pages to show before and after the current page
      let start = Math.max(1, currentPage - maxPagesToShow);
      let end = Math.min(totalPages, currentPage + maxPagesToShow);
  
      // Adjust for '...' before the first number
      if (start > 1) {
        paginationNumbers.push(1);
        if (start > 2) paginationNumbers.push("...");
      }
  
      for (let i = start; i <= end; i++) {
        paginationNumbers.push(i);
      }
  
      // Adjust for '...' after the last number
      if (end < totalPages) {
        if (end < totalPages - 1) paginationNumbers.push("...");
        paginationNumbers.push(totalPages);
      }
  
      return paginationNumbers;
    };
  
  



    const [isEditing, setIsEditing] = useState(false);
    const [editProductId, setEditProductId] = useState(null);
    const [removedImages, setRemovedImages] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        const response = await axios.get(`${BASE_URL}/api/products/`);
        setProducts(response.data);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct((prev) => ({ ...prev, [name]: value }));
    };
    const handleCheckboxChange = () => {
        setProduct(prevState => ({
            ...prevState,
            is_popular: !prevState.is_popular, // Toggle the checkbox value
        }));
    };
    const handleColorChange = (index, e) => {
        const { name, value } = e.target;
        const newColors = [...product.colors];
        newColors[index][name] = value;
        setProduct((prev) => ({ ...prev, colors: newColors }));
    };

    const addColor = () => {
        setProduct((prev) => ({
            ...prev,
            colors: [...prev.colors, { color_name: '', sizes: [{ size: '', quantity: 0 }] }]
        }));
    };

    const removeColor = (colorIndex) => {
        const newColors = product.colors.filter((_, index) => index !== colorIndex);
        setProduct((prev) => ({ ...prev, colors: newColors }));
    };

    const handleSizeChange = (colorIndex, sizeIndex, e) => {
        const { name, value } = e.target;
        const newColors = [...product.colors];
        newColors[colorIndex].sizes[sizeIndex][name] = value;
        setProduct((prev) => ({ ...prev, colors: newColors }));
    };

    const addSize = (colorIndex) => {
        const newColors = [...product.colors];
        newColors[colorIndex].sizes.push({ size: '', quantity: 0 });
        setProduct((prev) => ({ ...prev, colors: newColors }));
    };

    const removeSize = (colorIndex, sizeIndex) => {
        const newColors = [...product.colors];
        newColors[colorIndex].sizes = newColors[colorIndex].sizes.filter((_, idx) => idx !== sizeIndex);
        setProduct((prev) => ({ ...prev, colors: newColors }));
    };

    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const newImages = [...product.product_images];
            newImages[index] = { image: file };
            setProduct((prev) => ({
                ...prev,
                product_images: newImages
            }));
        }
    };

    const addAnotherImage = () => {
        setProduct((prev) => ({
            ...prev,
            product_images: [...prev.product_images, { image: null }]
        }));
    };

    const removeImage = (index) => {
        const imageToRemove = product.existing_images[index];
        if (imageToRemove) {
            setRemovedImages((prev) => [...prev, imageToRemove.image]);
        }
        const updatedExistingImages = product.existing_images.filter((_, imgIndex) => imgIndex !== index);
        setProduct((prev) => ({
            ...prev,
            existing_images: updatedExistingImages
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('product_name', product.product_name);
        formData.append('description', product.description);
        formData.append('price', product.price);
        formData.append('promo_percentage', product.promo_percentage);
        formData.append('badge', product.badge);
        formData.append('gender', product.gender);
        formData.append('season', product.season);
        formData.append('colors', JSON.stringify(product.colors));
        formData.append('is_popular', product.is_popular);
        product.product_images.forEach((image) => {
            if (image.image) {
                formData.append('product_images', image.image);
            }
        });

        removedImages.forEach((image) => {
            formData.append('removedImages', image);
        });
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            if (isEditing) {
                await axios.put(`${BASE_URL}/api/products/${editProductId}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setIsEditing(false);
                setEditProductId(null);
            } else {
                await axios.post(`${BASE_URL}/api/products/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            setProduct({
                product_name: '',
                description: '',
                price: '',
                promo_percentage:'0.00',
                badge: 'No Badge',
                gender: 'Men',
                season: 'New Arrivals',
                colors: [],
                product_images: [],
                existing_images: [],
                is_popular: false
            });
            setRemovedImages([]);
            fetchProducts();
            setShowForm(false);
        } catch (error) {
            console.error("Error submitting form: ", error);
        }finally {
            setIsSubmitting(false); 
        }
    };

    const handleEdit = (prod) => {
        setProduct({
            product_name: prod.product_name,
            description: prod.description,
            price: prod.price,
            promo_percentage: prod.promo_percentage,
            badge: prod.badge,
            gender: prod.gender,
            season: prod.season,
            colors: prod.colors || [],
            product_images: [],
            existing_images: prod.product_images,
            is_popular: prod.is_popular
        });
        setIsEditing(true);
        setEditProductId(prod.product_Id);
        setShowForm(true);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${BASE_URL}/api/products/${id}/`);
        fetchProducts();
    };

    const handleCloseDetails = () => {
        setEditProductId(null);
        setShowForm(false);
        setIsEditing(false);
        setProduct({
            product_name: '',
            description: '',
            price: '',
            promo_percentage:'0.00',
            badge: 'No Badge',
            gender: 'Men',
            season: 'New Arrivals',
            colors: [],
            product_images: [],
            existing_images: [],
            is_popular: false
        });
        setRemovedImages([]);
    };

    return (
        <div className="container mx-auto p-4">
            <button
                onClick={() => {
                    setShowForm(true);
                    setIsEditing(false);
                }}
                className="bg-green-500 text-white p-2 rounded mb-4"
            >
                Add New Product
            </button>

            <h2 className="text-2xl font-bold mt-6">Product List</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
            {currentProducts.map((prod) => (
                    <div key={prod.product_Id} className="border p-4 rounded shadow">
                        <div className="w-full relative group">
                            <div  className="relative overflow-hidden"
                                    style={{
                                    width: "100%", // Take full width of parent container
                                    height: "auto", // Allow height to adjust based on content
                                    }}>
                                         <style>
                                            {`
                                                @media (min-width: 768px) {
                                                .product-container {
                                                    width: 350px;
                                                    height: 450px;
                                                }
                                                }
                                                @media (min-width: 1024px) {
                                                .product-container {
                                                    width: 350px;
                                                    height: 450px;
                                                }
                                                }
                                            `}
                                         </style>
                                         <div className="product-container w-full h-full">
                                            <Image className="w-full h-full object-cover" imgSrc={`${BASE_URL}${prod.product_images[0]?.image}`} />
                                         </div>         
                                
                                <div className="absolute top-6 left-8">
                                    {prod.badge && prod.badge !== 'No Badge' && <Badge text={prod.badge} />}
                                </div>
                            </div>
                            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                                <h2 className="text-lg text-primeColor font-bold">{prod.product_name}</h2>
                                <div className="text-[#767676] text-[14px]">
                                    {prod.promo_percentage !== "0.00" ? (
                                    <>
                                        <span className="line-through">{prod.price} TND</span>
                                        <span className="ml-2 text-primeColor font-bold">
                                        {prod.discounted_price} TND
                                        </span>
                                    </>
                                    ) : (
                                    <span>{prod.price} TND</span>
                                    )}
                                </div>
                                <p className="text-[#767676] text-[14px]">Available Colors:</p>
                                <ul className="flex gap-2">
                                    {prod.colors.map((color, index) => (
                                        <li key={index} className="text-[#767676] text-[14px]">{color.color_name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="mt-2">
                            <button onClick={() => handleEdit(prod)} className="text-blue-500 underline">Edit</button>
                            <button onClick={() => handleDelete(prod.product_Id)} className="text-red-500 underline ml-2">Delete</button>
                        </div>
                    </div>
                ))}
            </div>
                 {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          {generatePaginationNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => typeof number === "number" && handlePageChange(number)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              disabled={typeof number === "string"} // Disable the button for ellipsis
            >
              {number}
            </button>
          ))}
       </div>
            {showForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg h-3/4 overflow-y-auto relative">
                        <h1 className="text-2xl font-bold mb-4">{isEditing ? 'Edit Product' : 'Add Product'}</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product_name">
                                    Product Name
                                </label>
                                <input
                                    type="text"
                                    id="product_name"
                                    name="product_name"
                                    value={product.product_name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={product.description}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product_name">
                                    Price
                                </label>
                                <input
                                    type="number"
                                    id="price"
                                    name="price"
                                    value={product.price}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product_name">
                                Promo Percentage
                                </label>
                                <input
                                    type="number"
                                    id="promo_percentage"
                                    name="promo_percentage"
                                    value={product.promo_percentage}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    // required
                                />
                            </div>
                          
                           
                            {/* Badge */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Badge</label>
                                <select
                                    name="badge"
                                    value={product.badge}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                >
                                    <option value="No Badge">No Badge</option>
                                    <option value="Promo">Promo</option>
                                </select>
                            </div>
                            {/* Gender and Season */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Gender</label>
                                <select
                                    name="gender"
                                    value={product.gender}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                >
                                    <option value="Men">Men</option>
                                    <option value="Women">Women</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Collection</label>
                                <select
                                    name="season"
                                    value={product.season}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                >
                                    <option value="New Arrivals">New Arrivals</option>
                                    <option value="Previous Collections">Previous Collections</option>
                                </select>
                            </div>
                            <div className="mt-4">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={product.is_popular}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                    />
                                    Popular product
                                </label>
                            </div>
                            {/* Color and Size */}
                            {product.colors.map((color, colorIndex) => (
                                <div key={colorIndex} className="mb-4">
                                    <div className="flex justify-between items-center">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Color {colorIndex + 1}
                                        </label>
                                        <button
                                            type="button"
                                            onClick={() => removeColor(colorIndex)}
                                            className="text-red-500 text-sm"
                                        >
                                            Remove Color
                                        </button>
                                    </div>
                                    <div className="border-2 border-black p-4 mb-4">
                                        <input
                                            type="text"
                                            name="color_name"
                                            value={color.color_name}
                                            onChange={(e) => handleColorChange(colorIndex, e)}
                                            className="w-full px-3 py-2 border rounded mb-2"
                                            placeholder="Enter color name"
                                            required
                                        />
                                        {color.sizes.map((size, sizeIndex) => (
                                            <div key={sizeIndex} className="flex items-center space-x-4 mb-2">
                                                <input
                                                    type="text"
                                                    name="size"
                                                    value={size.size}
                                                    onChange={(e) => handleSizeChange(colorIndex, sizeIndex, e)}
                                                    className="w-1/3 px-3 py-2 border rounded"
                                                    placeholder="Enter size"
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    value={size.quantity}
                                                    onChange={(e) => handleSizeChange(colorIndex, sizeIndex, e)}
                                                    className="w-1/3 px-3 py-2 border rounded"
                                                    placeholder="Enter quantity"
                                                    required
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => removeSize(colorIndex, sizeIndex)}
                                                    className="text-red-500 text-sm"
                                                >
                                                    Remove Size
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            onClick={() => addSize(colorIndex)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded text-sm"
                                        >
                                            Add Size
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={addColor}
                                className="bg-blue-500 text-white px-3 py-1 rounded text-sm mb-4"
                            >
                                Add Color
                            </button>
                            {/* Images */}
                            {product.existing_images.map((image, index) => (
                                <div key={index} className="mb-4">
                                    <div className="flex justify-between items-center">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Existing Image {index + 1}
                                        </label>
                                        <button
                                            type="button"
                                            onClick={() => removeImage(index)}
                                            className="text-red-500 text-sm"
                                        >
                                            Remove Image
                                        </button>
                                    </div>
                                    <img src={`${BASE_URL}${image.image}`} alt={`Product ${index + 1}`} className="w-40 h-40" />
                                </div>
                            ))}
                            {product.product_images.map((img, index) => (
                                <div key={index} className="mb-4">
                                    <div className="flex justify-between items-center">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            New Image {index + 1}
                                        </label>
                                    </div>
                                    <input
                                        type="file"
                                        name={`product_images_${index}`}
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(index, e)}
                                        className="w-full px-3 py-2 border rounded"
                                    />
                                </div>
                            ))}
                            <div>
                            <button
                                type="button"
                                onClick={addAnotherImage}
                                className="bg-blue-500 text-white px-3 py-1 rounded text-sm mb-4"
                            >
                                Add An Image
                            </button>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="submit"
                                    className={`bg-green-500 text-white px-4 py-2 rounded ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={isSubmitting}
                                >
                                     {isSubmitting ? 'Please wait...' : (isEditing ? 'Update Product' : 'Create Product')}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCloseDetails}
                                    className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Products;



