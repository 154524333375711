import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[#F5F5F3] pt-12 pb-12 font-poppins">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 px-4 gap-10">
        {/* Call Section */}
        <div className="col-span-2">
          <FooterListTitle title={t("Call")} />
          <div className="flex flex-col gap-4 text-lg tracking-wide text-gray-700">
            <p className="text-[1rem] leading-relaxed">
              <strong className="mr-2 text-primeColor">(+216)</strong> 26 810 820
            </p>
          </div>
        </div>

        {/* Shop Section */}
        <div>
          <FooterListTitle title={t("shop")} />
          <ul className="flex flex-col gap-2">
            <li className="text-base font-medium text-gray-600 hover:text-primeColor hover:underline decoration-[1px] decoration-primeColor underline-offset-4 cursor-pointer duration-200">
              <NavLink to="/shop" state={{ data: location.pathname.split("/")[1] }}>
                {t("Choose")}
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Orders Section */}
        <div>
          <FooterListTitle title={t("Orders")} />
          <ul className="flex flex-col gap-2">
            <li className="text-base font-medium text-gray-600 hover:text-primeColor hover:underline decoration-[1px] decoration-primeColor underline-offset-4 cursor-pointer duration-200">
              <NavLink to="/cart" state={{ data: location.pathname.split("/")[1] }}>
                {t("CART")}
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Social Media Connect Section */}
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title={t("Connect")} />
          <div className="w-full flex justify-center gap-3 mt-4">
            <a href="https://www.instagram.com/aria.brandx/" target="_blank" rel="noreferrer">
              <li className="w-10 h-10 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-xl rounded-full flex justify-center items-center hover:bg-black duration-200">
                <FaInstagram />
              </li>
            </a>
            <a href="https://www.facebook.com/Aria.brandx?mibextid=LQQJ4d&rdid=JIqLiBZA4TKCwoaO&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F12BRJTwZ1RX%2F%3Fmibextid%3DLQQJ4d#" target="_blank" rel="noreferrer">
              <li className="w-10 h-10 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-xl rounded-full flex justify-center items-center hover:bg-black duration-200">
                <FaFacebook />
              </li>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
