import React, { useState } from 'react';
import i18n from 'i18next';

// Import flag images
import enFlag from './assets/flags/en.png';
import frFlag from './assets/flags/fr.png';

function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState('fr'); // default language

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
    setIsOpen(false); // Close the dropdown after selection
  };

  const languages = [
    { code: 'fr', name: 'FR', flag: frFlag },
    { code: 'en', name: 'ENG', flag: enFlag },
  ];

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="font-poppins font-medium flex items-center px-3 py-2 sm:bg-primeColor md:bg-gray-200 rounded-md hover:bg-gray-300"
      >
        <img
          src={languages.find(lang => lang.code === selectedLang)?.flag}
          alt="Selected language flag"
          className="w-5 h-5 mr-2"
        />
        {languages.find(lang => lang.code === selectedLang)?.name}
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-36 sm:bg-primeColor md:bg-white border border-gray-200 rounded-md shadow-lg z-10">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className="flex items-center w-full px-4 py-2 hover:bg-gray-100"
            >
              <img src={lang.flag} alt={`${lang.name} flag`} className="w-5 h-5 mr-2" />
              {lang.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
