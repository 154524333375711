// Shop.js
import React, { useState, useEffect } from "react";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_API_URL;

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false); // For responsive sidebar toggle
  const [resetPage, setResetPage] = useState(false); // New state to reset page in pagination
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gender = queryParams.get("gender");
  console.log("Selected gender:", gender);
  const { t } = useTranslation();
  const updateProducts = (newProducts) => {
    setProducts(newProducts);
    setLoading(false); // Stop loading when products are updated
    setResetPage((prev) => !prev); // Toggle resetPage to reset pagination to page 1
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const endpoint = gender
        ? `${BASE_URL}/api/products/gender/${gender}/`
        : `${BASE_URL}/api/products/`;
      const response = await axios.get(endpoint);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [gender]);

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Sidebar */}
      <div
        className={`fixed lg:sticky lg:top-12 lg:mt-12 lg:h-auto h-screen w-4/5 lg:w-1/5 bg-white shadow-lg z-50 transform ${
          showSidebar ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 lg:translate-x-0 overflow-y-auto max-h-screen`}
      >
        <ShopSideNav updateProducts={updateProducts} closeSidebar={closeSidebar} />
        <button
          className="lg:hidden absolute top-3 right-4 text-lg"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          ✕
        </button>
      </div>

      {/* Main content */}
      <div className="w-full lg:w-4/5 p-4 ml-auto">
        <button
          className="lg:hidden mb-4 text-lg"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          ☰ Categories
        </button>

        {/* Display loading indicator before products are fetched */}
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <span className="dot-animation">• • •</span>
            <style>{`
              .dot-animation {
                font-size: 2rem;
                display: inline-block;
                animation: dots 1.5s infinite linear;
              }
              @keyframes dots {
                0% { content: "•"; }
                33% { content: "• •"; }
                66% { content: "• • •"; }
                100% { content: "•"; }
              }
            `}</style>
          </div>
        ) : (
          <Pagination products={products} resetPage={resetPage} />
        )}
      </div>
    </div>
  );
};

export default Shop;
