import React, { useState } from "react";

const Image = ({ imgSrc, className, placeholderSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {/* Show placeholder image until the main image is loaded */}
      {!isLoaded && (
        <img
          src={placeholderSrc}
          alt="Loading placeholder"
          className={className}
        />
      )}

      {/* Main image, hidden until loaded */}
      <img
        src={imgSrc}
        alt="Product"
        className={className}
        onLoad={() => setIsLoaded(true)}
        style={{ display: isLoaded ? "block" : "none" }} 
      />
    </>
  );
};

export default Image;
