import React from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SpecialCase = () => {
  const products = useSelector((state) => state.shopReducer.products);
  const { t } = useTranslation();
  return (
    <div className="fixed top-52 right-2 z-20 md:flex flex-col gap-2">
      <Link to="/cart">
        <div className="bg-white md:w-16 sm:w-11 md:h-[70px] sm:h-[45px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative">
          <div className="flex justify-center items-center">
            <RiShoppingCart2Fill className="text-xl sm:text-2xl -translate-x-10 sm:-translate-x-12 group-hover:translate-x-2 sm:group-hover:translate-x-3 transition-transform duration-200" />
            <RiShoppingCart2Fill className="text-xl sm:text-2xl -translate-x-2 sm:-translate-x-3 group-hover:translate-x-10 sm:group-hover:translate-x-12 transition-transform duration-200" />
          </div>
          <p className="text-xs font-semibold hidden md:flex font-titleFont">{t("Buy")}</p>
          {products.length > 0 && (
            <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
              {products.length}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default SpecialCase;
