import React from "react";

const Heading = ({ heading }) => {
  return <div className="flex justify-start ml-4 mt-4 pb-2">
  <h1 className="text-lg pb-2 font-medium text-gray-800 border-b-2 border-gray-800 transition duration-200">
    {heading}
  </h1>
</div>
};

export default Heading;
