import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { logo,logoblanc } from "../../../assets/images"; // Use `logo` for standard color logo
import Image from "../../designLayouts/Image";
import LanguageSelector from "../../../LanguageSelector"; 
import { useTranslation } from "react-i18next";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const ResponsiveMenu = () => {
      setShowMenu(window.innerWidth >= 667);
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
    return () => window.removeEventListener("resize", ResponsiveMenu);
  }, []);

  const handleHomeClick = (e) => {
    if (location.pathname === "/") {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <header className="w-full h-20 bg-white shadow-md sticky top-0 z-50 border-b">
      <nav className="h-full px-6 max-w-7xl mx-auto flex items-center justify-between">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <Image className="w-32" imgSrc={logo} alt="Logo" />
        </Link>

        {/* Desktop Menu */}
        {showMenu && (
          <motion.ul
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="flex items-center space-x-8 text-black text-sm"
          >
           <NavLink 
            to="/" 
            onClick={handleHomeClick} 
            className={({ isActive }) =>
              `font-poppins font-medium ${isActive ? "underline" : "hover:underline"}`
            }
          >
            {t("Home")}
          </NavLink>
          
          <NavLink 
  to="/about" 
  className={({ isActive }) =>
    `font-poppins font-medium ${isActive ? "underline" : "hover:underline"}`
  }
>
  {t("About")}
</NavLink>

  <NavLink 
    to="/shop" 
    state={{ data: location.pathname.split("/")[1] }} 
    className={({ isActive }) =>
      `font-poppins font-medium ${isActive ? "underline" : "hover:underline"}`
    }
  >
    {t("shop")}
  </NavLink>

  <NavLink 
    to="/contact" 
    state={{ data: location.pathname.split("/")[1] }} 
    className={({ isActive }) =>
      `font-poppins font-medium ${isActive ? "underline" : "hover:underline"}`
    }
  >
    {t("Contact")}
  </NavLink>

            
            
            <LanguageSelector />
          </motion.ul>
        )}

<HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    <img
                      className="w-56 mb-6"
                      src={logoblanc}
                      alt="logoLight"
                    />
                    
                    <ul className="px-3 text-gray-200 flex flex-col gap-2">
                     
                        <li
                          className="font-poppins font-medium  hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
      
                        >
                          <NavLink
                            to="/"
                            className={({ isActive }) =>
                              isActive
                                ? "underline font-bold text-white"
                                : "text-gray-200"
                            }
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                           {t("Home")}
                          </NavLink>
                        </li>
                        <li
                          className="font-poppins font-medium hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                        >
                          <NavLink
                            to="/about"
                            className={({ isActive }) =>
                              isActive
                                ? "underline font-bold text-white"
                                : "text-gray-200"
                            }
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                             {t("About")}
                          </NavLink>
                        </li>
                       
                        <li
                          className="font-poppins font-medium  hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
      
                        >
                          <NavLink
                            to="/shop"
                            className={({ isActive }) =>
                              isActive
                                ? "underline font-bold text-white"
                                : "text-gray-200"
                            }
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                             {t("Shop")}
                          </NavLink>
                        </li>
                        <li
                          className="font-poppins font-medium  hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
      
                        >
                          <NavLink
                            to="/contact"
                            className={({ isActive }) =>
                                isActive
                                  ? "underline font-bold text-white"
                                  : "text-gray-200"
                              }
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {t("Contact")}
                          </NavLink>
                        </li>
                        
                    </ul>
                    <div className="mt-2 flex justify-start items-center">
                    <LanguageSelector />
                  </div>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
      </nav>
    </header>
  );
};

export default Header;