import React,{ useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { useTranslation } from "react-i18next";

const BASE_URL = process.env.REACT_APP_API_URL;

const Category = ({ gender, updateProducts, closeSidebar, isOpen, toggleCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState("New Arrivals"); // Default selected category
  const { t } = useTranslation();

  const brands = [
    { _id: 9006, title: "New Arrivals" },
    { _id: 9007, title: "Previous Collections" },
  ];

  const fetchCategoryProducts = (category) => {
    return fetch(`${BASE_URL}/api/products/gender/${gender}/season/${category}/`)
      .then((response) => response.json())
      .then((data) => {
        updateProducts(data);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    fetchCategoryProducts(category);
    if (window.innerWidth < 1024) {
      closeSidebar(); // Close the sidebar when a category is selected
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div onClick={toggleCategory} className="cursor-pointer mb-4">
        <NavTitle title={`${t("Shopby")} ${t(gender)} ${t("Category")}`} icons={true} />
      </div>

      {isOpen && (
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <ul className="space-y-2 text-sm lg:text-base text-gray-700">
            {brands.map((item) => (
              <li
                key={item._id}
                className={`px-4 py-3 transition-all duration-300 cursor-pointer rounded-md flex justify-between items-center ${
                  selectedCategory === item.title
                    ? "bg-gray-200 text-gray-900 font-semibold"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => handleCategorySelect(item.title)}
              >
                <span>{t(item.title)}</span>
                {/* {selectedCategory === item.title && (
                  <motion.div
                    className="w-2.5 h-2.5 rounded-full bg-blue-500"
                    layoutId="selectedIndicator"
                  ></motion.div>
                )} */}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Category;
