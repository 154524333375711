// Pagination.js
import React, { useState, useEffect } from "react";
import Product from "../../home/Products/Product"; // Assuming you have a Product component to display
import { useTranslation } from "react-i18next";
const BASE_URL = process.env.REACT_APP_API_URL;

const Pagination = ({ products, resetPage }) => {
  const productsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  // Reset the page to 1 when resetPage is triggered (e.g., category selection)
  useEffect(() => {
    setCurrentPage(1);
  }, [resetPage]);

  // Calculate total pages
  const totalPages = Math.ceil(products.length / productsPerPage);

  // Calculate the current products to display
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to generate pagination numbers
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    const maxPagesToShow = 2; // Number of pages to show before and after the current page
    let start = Math.max(1, currentPage - maxPagesToShow);
    let end = Math.min(totalPages, currentPage + maxPagesToShow);

    // Adjust for '...' before the first number
    if (start > 1) {
      paginationNumbers.push(1);
      if (start > 2) paginationNumbers.push("...");
    }

    for (let i = start; i <= end; i++) {
      paginationNumbers.push(i);
    }

    // Adjust for '...' after the last number
    if (end < totalPages) {
      if (end < totalPages - 1) paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  return (
    <div className="p-4">
      <h2 className="font-poppins text-2xl font-bold mb-6 text-center">{t("ProdList")}</h2>
      {products.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
          {currentProducts.map((product) => (
            <Product
              key={product.product_Id}
              _id={product.product_Id}
              img={`${BASE_URL}${product.product_images[0]?.image}`} 
              images={product.product_images}
              productName={product.product_name}
              price={product.price}
              promo_percentage={product.promo_percentage}
              discounted_price={product.discounted_price}
              colors={product.colors}
              badge={product.badge}
              des={product.description}
              gender={product.gender}
              season={product.season}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-64">
          <p className="font-poppins text-lg font-medium text-gray-600">
            No products available for this category
          </p>
        </div>
      )}
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        {generatePaginationNumbers().map((number, index) => (
          <button
            key={index}
            onClick={() => typeof number === "number" && handlePageChange(number)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            disabled={typeof number === "string"} // Disable the button for ellipsis
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Pagination;
